<template>

  <div>


    <div class="row d-none">

      <div class="col-md-12">

        <!-- Load JSON data -->
        <div class="card ">
          <div class="card-header header-elements-inline">
            <h6 class="card-title">Trail Balance</h6>
            <div class="header-elements">
              <div class="list-icons">
                <a class="list-icons-item" data-action="collapse"></a>
                <a class="list-icons-item" data-action="reload"></a>
                <a class="list-icons-item" data-action="remove"></a>
              </div>
            </div>
          </div>

          <div class="card-body">

            <div class="tree-ajax card card-body border-left-info border-left-2 shadow-0 rounded-left-0">

            </div>
          </div>
        </div>
        <!-- /load JSON data -->

      </div>
    </div>

    <div class="card">

      <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
        <div class="form-group row">
          <div class="col">
            <h5>Trial Balance</h5>
          </div>


          <label class="col-md-1 col-form-label">As On:</label>
          <div class="col-md-2">
            <input type="date"  class="form-control" placeholder="From Date"  v-model="ason_date" >
          </div>

          <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="showTrailBalance">Show</button>
        </div>

      </div>


      <div class="table-responsive">
        <table class="table table-xs table-columned table-hover tree-table fancytree-container fancytree-ext-table" tabindex="0" role="treegrid" aria-multiselectable="true" aria-readonly="true">
          <thead>
          <tr>
            <th style="width: 46px;"></th>
            <th style="width: 80px;">#</th>
            <th>Group</th>
            <th style="width: 120px;">Debit</th>
            <th style="width: 120px;">Credit</th>
          </tr>
          </thead>
          <tbody>

          </tbody>
          <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

            <tr  v-if="diff_total != 0" >
              <td></td>
              <td></td>
              <td style="text-align: right;">Difference in Opening Balance</td>
              <td style="width: 120px;text-align: right;">{{debit_balance  | indianFormat}}</td>
              <td style="width: 120px;text-align: right;">{{credit_balance | indianFormat}}</td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td style="text-align: right;">Total</td>
              <td style="width: 120px;text-align: right;">{{debit_balance + debit_total | indianFormat}}</td>
              <td style="width: 120px;text-align: right;">{{credit_balance + credit_total | indianFormat}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'TrialBalanceView',
    store,
    data () {
      return {
        current_ibr : localStorage.getItem('branch_code'),
        NumFormat : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }),
        ason_date: moment().format('YYYY-MM-DD'),
        fancytree: {},
        credit_total:0.0,
        debit_total:0.0,
        data_loaded:false,
      }
    },
    computed:{
      diff_total : function () {
        return this.$data.credit_total - this.$data.debit_total;
      },
      debit_balance:function () {
        if ((this.$data.credit_total - this.$data.debit_total) >0){
          return this.$data.credit_total - this.$data.debit_total;
        }
        return 0;
      },
      credit_balance:function () {
        if ((this.$data.debit_total - this.$data.credit_total ) >0){
          return this.$data.debit_total - this.$data.credit_total;
        }
        return 0;
      },
    },
    created(){

    },
    mounted () {

      const self = this;

      self.$data.ason_date = moment().format('YYYY-MM-DD');

      self.$data.fancytree = function () {

        // Uniform
        let _componentFancytree = function () {
          if (!$().fancytree) {
            return;
          }

          //
          // Table tree
          // https://github.com/mar10/fancytree
          //
          $('.tree-table').fancytree({
            extensions: ['table'],
            checkbox: false,
            table: {
              indentation: 20,      // indent 20px per node level
              nodeColumnIdx: 2,     // render the node title into the 2nd column
              checkboxColumnIdx: 0  // render the checkboxes into the 1st column
            },
            source: {
              url: `${process.env.VUE_APP_ROOT_API}v1/accounts/trialbalance/?ibr=${self.$data.current_ibr}&date=${self.$data.ason_date}`
            },
            init: function(event, data, flag) {
              self.$data.data_loaded = true;
            },
            renderColumns: function (event, data) {

              let node = data.node, $tdList = $(node.tr).find('>td');

              // (index #0 is rendered by fancytree by adding the checkbox)

              $tdList.eq(1).text(node.getIndexHier()).addClass('alignRight')

              // (index #2 is rendered by fancytree)

              // $tdList.eq(4).text(indianFormat(node.data.credit));
              if (node.data.balance < 0 ){
                $tdList.eq(3).addClass('text-right').html(indianFormat( Math.abs(node.data.balance)));
                if( !self.$data.data_loaded){
                  self.$data.debit_total += Math.abs(node.data.balance);
                }
              }else{
                $tdList.eq(4).addClass('text-right').html(indianFormat( Math.abs(node.data.balance)));

                if( !self.$data.data_loaded){
                  self.$data.credit_total += node.data.balance;
                }
              }


              self.$data.diff_total += (self.$data.credit_total - self.$data.debit_total);


              self.$data.data_loaded = false;
            }
          })

          // Handle custom checkbox clicks
          $('.tree-table').on('input[name=like]', 'click', function (e) {
            let node = $.ui.fancytree.getNode(e),
              $input = $(e.target)
            e.stopPropagation() // prevent fancytree activate for this row
            if ($input.is(':checked')) {
              alert('like ' + $input.val())
            }
            else {
              alert('dislike ' + $input.val())
            }
          })
        }

        //
        // Return objects assigned to module
        //

        return {
          init: function () {
            self.$data.debit_total = 0.0;
            self.$data.credit_total = 0.0;
            self.$data.diff_total = 0.0;
            _componentFancytree();
          }
        }
      }();

      // self.$data.fancytree.init()
    },
    filters:{
      formatDate(val){
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {

        if(value !== 0)
          return NumFormat.format(value);// value.toFixed(2);
        else
          return '';
      },
    },
    methods:{
      showTrailBalance(){
        const self = this;
        // this.$data.fancytree.destroy();
        $(":ui-fancytree").fancytree("destroy");

        self.$data.data_loaded = false;
        this.$data.fancytree.init();

      }
    }
  }
</script>

<style scoped>

</style>
